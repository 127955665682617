import React, { useEffect } from "react";
import Layout from "../components/Layout";

const SignUpPage = () => {
  useEffect(() => {
    window.gtag("event", "conversion", {
      send_to: "AW-10851202604/VHEBCKffy50DEKzsoLYo",
    });
    window.location.replace(
      `${process.env.GATSBY_APP_ORIGIN}?signup=true`
    );
  }, []);

  return (
    <Layout>
      <></>
    </Layout>
  );
};

export default SignUpPage;
